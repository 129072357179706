import React from 'react';
import { graphql } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { Carousel } from '../components/Carousel';
import { ImagePass } from '../components/Image/ImagePass';
import { SocialLinks } from '../components/SocialLinks';
import { StandardHeader } from '../components/Acf/StandardHeader';
import { RecentProjects } from '../components/Acf/RecentProjects';
import { ContactCtaBlock } from '../components/Acf/ContactCtaBlock';
import { RenderShortcode } from '../components/RenderShortcode';
import { decodeEntities, slugify } from '../utils/helpers';
import './project.scss';

const ProjectDetailsRow = ({ children, title, isCostRow }) => {
  if (!isCostRow && (!children || typeof children === 'undefined')) return null;
  return (
    <div className="project-details-row">
      {title && <h3>{decodeEntities(title)}</h3>}
      {children && <span>{decodeEntities(children)}</span>}
    </div>
  );
};

export const ProjectPostTemplate = ({
  content,
  categories,
  title,
  tags,
  acf,
}) => {
  const {
    projectCost,
    client,
    location,
    duration,
    startDate,
    finishDate,
    images,
  } = acf;
  const settings = {
    infinite: true,
    adaptiveHeight: true,
    dots: false
  };
  return (
    <section className="single-project">
      <div className="wrap">
        <div className="project-content-container">
          <div className="project-content">
            {categories && categories.length ? (
              <div className="categories">
                <ul className="taglist">
                  {categories.map(category => (
                    <li key={`${category.slug}cat`}>
                      {category.slug && (
                        <GatsbyLink to={`/case-studies/category/${category.slug}/`}>
                          {category.name}
                        </GatsbyLink>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <h1><strong>{decodeEntities(title)}</strong></h1>
            <RenderShortcode content={content} />
          </div>
          <div className="project-details">
            <div className="project-details-container">
              <div className="project-details-rows">
                <ProjectDetailsRow first>{projectCost}</ProjectDetailsRow>
                <ProjectDetailsRow title="Client">{client}</ProjectDetailsRow>
                <ProjectDetailsRow title="Location">{location}</ProjectDetailsRow>
                <ProjectDetailsRow title="Project Duration">{duration}</ProjectDetailsRow>
                <ProjectDetailsRow title="Project Start">{startDate}</ProjectDetailsRow>
                <ProjectDetailsRow title="Project Finish">{finishDate}</ProjectDetailsRow>
              </div>
            </div>
          </div>
        </div>
        {images && images.length > 0 && (
          <div className="project-gallery">
            <Carousel {...settings}>
              {images.map(image => <ImagePass src={image} position="relative" />)}
            </Carousel>
          </div>
        )}
        <div className="project-footer">
          {tags && tags.length ? (
            <div className="tags">
              <span>Tags</span>
              <ul className="taglist">
                {tags.map(tag => (
                  <li key={`${slugify(tag.name)}-tag`}>
                    <span>{tag.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          <div className="share-links">
            <span>Share</span>
            <SocialLinks share />
          </div>
        </div>
      </div>
    </section>
  )
}

const ProjectPost = ( props ) => {
  const { data, location } = props;
  const { projectsPage, wordpressPost: post, wordpressWpSettings } = data;
  if (!post) return null;
  const { title, content, featuredImage, yoast, categories, tags, date, author, acf: projectFields } = post;
  const {
    // Standard Header fields
    standardHeaderTitleBold,
    standardHeaderTitleRegular,
    standardHeaderSubtitleBold,
    standardHeaderSubtitleRegular,
    standardHeaderImage,
    // Contact CTA Block fields
    contactCtaTitleBold,
    contactCtaTitleRegular,
    contactCtaContent,
    contactCtaLink,
    contactCtaImage,
  } = projectsPage.acf;
  return (
    <Layout>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={yoast.metaDescription}
        yoast={yoast}
      />
      <StandardHeader
        pageTitle={title}
        standardHeaderTitleBold={standardHeaderTitleBold}
        standardHeaderTitleRegular={standardHeaderTitleRegular}
        standardHeaderSubtitleBold={standardHeaderSubtitleBold}
        standardHeaderSubtitleRegular={standardHeaderSubtitleRegular}
        standardHeaderImage={featuredImage || standardHeaderImage}
        location={location}
        useSpan
      />
      <ProjectPostTemplate
        content={content}
        categories={categories}
        tags={tags}
        title={title}
        date={date}
        author={author}
        acf={projectFields}
        blogSlug="projects"
      />
      <RecentProjects
        titleBold="Our"
        titleRegular="Recent Projects"
        content="We’re proud of our achievements. Read about our recent projects and case studies to see what we’ve been working on and how we could work with you."
        link={{
          url: '/case-studies/',
          title: 'View all projects'
        }}
      />
      <ContactCtaBlock
        contactCtaTitleBold={contactCtaTitleBold}
        contactCtaTitleRegular={contactCtaTitleRegular}
        contactCtaContent={contactCtaContent}
        contactCtaLink={contactCtaLink}
        contactCtaImage={contactCtaImage}
      />
    </Layout>
  )
}

export default Previewable(ProjectPost, 'projects');

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProjectPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    projectsPage: wordpressPage(slug: {eq: "case-studies"}) {
      acf {
        ... StandardHeaderNonFlexibleQuery
        ... ContactCtaBlockNonFlexibleQuery
      }
    }
    wordpressPost: wordpressWpProjects(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featuredImage: featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ... GatsbyImageSharpFluid
            }
          }
        }
      }
      tags {
        name
      }
      categories {
        name
        slug
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
      acf {
        projectCost
        client
        location
        duration
        startDate
        finishDate
        images {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ... GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
